export default (e) => {
	if (e.errors && e.errors.msg) {
			if (Array.isArray(e.errors.msg)) {
					return e.errors.msg.map(item => item.msg)
			} else if (typeof e.errors.msg === 'string') {
					return [e.errors.msg]
			}
	}

	if (typeof e === 'string') {
			return [e]
	}

	return ['SERVER_ERROR']
}