import React, { Fragment, Suspense, lazy, useContext, useEffect } from "react";
import { AppState } from "./AppState";
import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from "@mui/material";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import Pace from "./components/Pace";
import "./app.css";

const Authenticated = lazy(() => import("./authenticated"));
const Home = lazy(() => import("./landing/Landing"));
const Signup = lazy(() => import("./signup/Signup"));
const Login = lazy(() => import("./login/Login"));

function App() {
  const { user } = useContext(AppState);

  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          <Pace color={theme.palette.primary.light} />
          <Suspense fallback={<></>}>
            <Switch>
              {user ? (
                <>
                  <Authenticated />
                </>
              ) : (
                <>
                  <Route path="/" exact>
                    <Home />
                  </Route>
                  <Route path="/signup" exact>
                    <Signup />
                  </Route>
                  <Route path="/login" exact>
                    <Login />
                  </Route>
                </>
              )}
            </Switch>
          </Suspense>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}

export default App;
