import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { ContextWrapper } from './AppState'

ReactDOM.render(
  <ContextWrapper><App /></ContextWrapper>,
  document.getElementById('root')
)

serviceWorkerRegistration.register()
