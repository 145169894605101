import React, { useEffect, createContext, useState } from 'react'
import useLocalStorage from './utils/useLocalStrorage'
import axios from 'axios'
import flattenErrors from './utils/flattenErrors'
import { API_URL } from './config'

const initialState = {
  restoringSession: false,
  user: null
}

export const AppState = createContext(initialState)

export const ContextWrapper = props => {
  const [session, saveSession] = useLocalStorage('session', initialState)
  const [state, setState] = useState(initialState)

  useEffect(() => {
    (async () => {
      //restoring session here
      if (session) { // saved session is available
        setState(prevState => ({ ...prevState, user: session.user, restoringSession: false }))
      } else { // no saved session
        setState(prevState => ({ ...prevState, restoringSession: false }))
      }
    })()
  }, [])

  const asyncActions = {
    authenticateWithWallet : async (address) => {
      const email = `${address}@crypto.com`;
      const token = `${address}`;
      saveSession({...session, user: {authType: 'WALLET', email, token, account : address}})
      setState({...state, user: {authType: 'WALLET', email, token, account : address}})
    },
    register: async (name, email, password) => {
      const headers = { 'Content-Type': 'application/json' }
      try {
        const resp = await axios.post(API_URL + '/register', { name, email, password }, { headers })
        const user = {authType: 'JWT', token:resp.data.token, ...resp.data.user}
        saveSession({...session, user})
        setState({...state, user})
        return user
      } catch (error) {
        throw flattenErrors(error.response.data)
      }
    },
    login: async (email, password) => {
      const headers = { 'Content-Type': 'application/json' }
      try {
        const resp = await axios.post(API_URL + '/login', { email, password }, { headers })
        const user = {authType: 'JWT', token:resp.data.token, ...resp.data.user}
        saveSession({...session, user})
        setState({...state, user})
        return user
      } catch (error) {
        throw flattenErrors(error.response.data)
      }
    },
    signOut: async () => {
      saveSession(initialState)
      setState(initialState)
    },
    httpGet: async (url) => {
      const headers = { 'Content-Type': 'application/json' }
      if (state.user.token) {
        headers.Authorization = 'Bearer ' + state.user.token
      }
      try {
        const resp = await axios.get(API_URL + url, { headers })
        return resp
      } catch (error) {
        console.log(error.response.data)
        throw new Error('Request Failed')
      }
    },
    httpPost: async (url, body) => {
      const headers = { 'Content-Type': 'application/json' }
      if (state.user.token) {
        headers.Authorization = 'Bearer ' + state.user.token
      }
      try {
        const resp = await axios.post(API_URL + url, body, { headers })
        return resp
      } catch (error) {
        console.log(error.response.data)
        throw new Error('Request Failed')
      }
    },
    httpPatch: async (url, body) => {
      const headers = { 'Content-Type': 'application/json' }
      if (state.user.token) {
        headers.Authorization = 'Bearer ' + state.user.token
      }
      try {
        const resp = await axios.patch(API_URL + url, body, { headers })
        return resp
      } catch (error) {
        console.log(error.response.data)
        throw new Error('Request Failed')
      }
    },
    httpDelete: async (url) => {
      const headers = { 'Content-Type': 'application/json' }
      if (state.user.token) {
        headers.Authorization = 'Bearer ' + state.user.token
      }
      try {
        const resp = await axios.delete(API_URL + url, { headers })
        return resp
      } catch (error) {
        console.log(error.response.data)
        throw new Error('Request Failed')
      }
    }
  }

  return (
    <AppState.Provider value={{ ...state, ...asyncActions }}>
      {props.children}
    </AppState.Provider>
  )
}